export default {
  getRangeOfDates,
  convertSeconds,
  getEnv,
  getAppUsers,
};

export const defaultDaysOff = 21;

export function getRangeOfDates(start, end, key, arr = [start.startOf(key)]) {
  if (start.isAfter(end)) throw new Error("start must precede end");

  const next = moment(start).add(1, key).startOf(key);

  if (next.isAfter(end, key)) return arr;

  return getRangeOfDates(next, end, key, arr.concat(next));
}

export function convertSeconds(d) {
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);
  // console.log(m)
  if (isNaN(h)) return global_.no_data;
  return `${h}H:${m < 10 ? "0" + m : m}M`;
}

function getEnv(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${url}`, { headers: { itsMe: "Vue" } })
      .then((r) => resolve(r))
      .catch((e) => {
        reject(e);
      });
  });
}

export function getAppUsers(url) {
  return axios
    .get(url + "getAppUsers")
    .then((list) => list.data)
    .catch((error) => {
      console.log(error);
      if (error.response) {
        toastr.error(error.response.data);
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        toastr.error(error.request);
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    });
}

export function availableWalletBudget(
  boughtProducts,
  initialAvailableBudget,
  exchangeRateUsdToRon,
  exchangeRateUsdToEur
) {
  let unpaidSum = 0;
  boughtProducts.forEach((order) => {
    order.forEach((product) => {
      if (
        product.installments !== 1 &&
        product.currentInstallment < product.paymentInstallments
      ) {
        if (product.currency == "ron") {
          unpaidSum +=
            product.price / exchangeRateUsdToRon -
            (product.price /
              exchangeRateUsdToRon /
              product.paymentInstallments) *
              product.currentInstallment;
        } else if (product.currency == "eur") {
          unpaidSum +=
            product.price / exchangeRateUsdToEur -
            (product.price /
              exchangeRateUsdToEur /
              product.paymentInstallments) *
              product.currentInstallment;
        } else {
          unpaidSum +=
            product.price -
            (product.price / product.paymentInstallments) *
              product.currentInstallment;
        }
      }
    });
  });
  let availableBudgetUsd = initialAvailableBudget.budget - unpaidSum;
  let availableBudgetRon = availableBudgetUsd * exchangeRateUsdToRon;
  let availableBudgetEur = availableBudgetUsd * exchangeRateUsdToEur;

  return {
    usdWallet: availableBudgetUsd,
    ronWallet: availableBudgetRon,
    eurWallet: availableBudgetEur,
  };
}
