import Vue from 'vue'
import Vuex from 'vuex'

/**
 * Global Store
 */
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import state from './state'
import tasks from './modules/tasks'
import notifications from './modules/notifications'
import accounts from './modules/accounts'
import shift from './modules/shift'

/**
 * Modules
 */

Vue.use(Vuex)

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    tasks,
    accounts,
    notifications,
    shift,
  }
});

export default store