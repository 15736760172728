export default [
  {
    path: "/",
    component: () => import("@/layouts/Login.vue"),
    children: [
      { path: "", name: "login", component: () => import("@/views/Login.vue") },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/Setup.vue"),
    children: [
      {
        path: "setup",
        name: "setup",
        component: () => import("@/views/Setup.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/Default.vue"),
    children: [
      // { path: 'chat', name: 'chat', component: () => import('@/views/Chat.vue') },
      // { path: 'proxy', name: 'proxy', component: () => import('@/views/proxy.vue') },
      // { path: 'controller_users', name: 'controller_users', component: () => import('@/views/ControllerUsers.vue') },
      {
        path: "settlement-reports",
        name: "Settlement reports",
        component: () => import("@/views/Settlements.vue"),
      },
      {
        path: "inbox",
        name: "inbox",
        component: () => import("@/views/Inbox.vue"),
      },
      {
        path: "monitoring",
        name: "monitoring",
        component: () => import("@/views/Monitoring.vue"),
      },
      {
        path: "training",
        name: "training",
        component: () => import("@/views/Training.vue"),
      },
      {
        path: "accounts",
        name: "accounts",
        component: () => import("@/views/Accounts.vue"),
      },
      {
        path: "penalties",
        name: "penalties",
        component: () => import("@/views/Penalties.vue"),
      },
      {
        path: "money",
        name: "money",
        component: () => import("@/views/Money.vue"),
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "emails",
        name: "emails",
        component: () => import("@/views/Emails.vue"),
      },
      {
        path: "chat",
        name: "chat",
        component: () => import("@/views/Chat.vue"),
      },
      {
        path: "about",
        name: "about",
        component: () => import("@/views/About.vue"),
      },
      {
        path: "boost",
        name: "boost",
        component: () => import("@/views/BoostLogs.vue"),
      },
      {
        path: "about-user",
        name: "about-user",
        component: () => import("@/views/AboutUser.vue"),
      },
      {
        path: "vacations",
        name: "vacations",
        component: () => import("@/views/Vacations.vue"),
      },
      {
        path: "tasks",
        name: "tasks",
        component: () => import("@/views/Tasks.vue"),
      },
      {
        path: "wallet",
        name: "wallet",
        component: () => import("@/views/Store/Wallet.vue"),
      },
      // { path: 'add_task', name: 'add_task', component: () => import('@/views/AddTask.vue') },
      {
        path: "interviews",
        name: "interviews",
        component: () => import("@/views/Interviews.vue"),
      },
      {
        path: "add_interview",
        name: "add_interview",
        component: () => import("@/views/AddInterview.vue"),
      },
      {
        path: "calendar_interviews",
        name: "calendar_interviews",
        component: () => import("@/views/CalendarInterviews.vue"),
      },
      {
        path: "notes",
        name: "notes",
        component: () => import("@/views/Notes.vue"),
      },
      {
        path: "usage",
        name: "usage",
        component: () => import("@/views/Usage.vue"),
      },
      {
        path: "upfronts",
        name: "upfronts",
        component: () => import("@/views/Accountancy/Upfronts.vue"),
      },
      {
        path: "bonuses",
        name: "bonuses",
        component: () => import("@/views/Accountancy/Bonuses.vue"),
      },
      {
        path: "schedule",
        name: "schedule",
        component: () => import("@/views/Schedule.vue"),
      },
      {
        path: "posts",
        name: "posts",
        component: () => import("@/views/Posts.vue"),
      },
      // { path: 'model_bonuses', name: 'model_bonuses', component: () => import('@/views/ModelBonuses.vue') },
      {
        path: "members-logins",
        name: "members-logins",
        component: () => import("@/views/MembersLogins.vue"),
      },
      // store
      {
        path: "/store",
        name: "store",
        component: () => import("@/views/Store/Index.vue"),
        props: true,
      },
      {
        path: "/checkout",
        name: "checkout",
        component: () => import("@/views/Store/Checkout.vue"),
        props: true,
      },
      {
        path: "/soldProductsHistory",
        name: "soldProductsHistory",
        component: () => import("@/views/Store/SoldProductsHistory.vue"),
        props: true,
      },
      {
        path: "/purchasedProducts",
        name: "purchasedProducts",
        component: () => import("@/views/Store/CustomerPurchaseHistory.vue"),
        props: true,
      },

      // Studio Configuration
      {
        path: "studio-config",
        name: "studio-config",
        component: () => import("@/views/StudioConfig.vue"),
      },
      {
        path: "studio-permissions",
        name: "studio-permissions",
        component: () => import("@/views/StudioPermissions.vue"),
      },
      {
        path: "model-assignment",
        name: "model-assignment",
        component: () => import("@/views/ModelAssignment.vue"),
      },
      // Bonus configs
      {
        path: "bonus-config/studio",
        name: "bonus-config-studio",
        component: () => import("@/views/BonusConfig/Studio.vue"),
      },
      {
        path: "bonus-config/models",
        name: "bonus-config-models",
        component: () => import("@/views/BonusConfig/Model.vue"),
      },
      {
        path: "bonus-config/trainers",
        name: "bonus-config-trainers",
        component: () => import("@/views/BonusConfig/Trainer.vue"),
      },
      // Station manager configs
      {
        path: "station-config/radmin",
        name: "station-config-radmin",
        component: () => import("@/views/InternalConfig/RadminManager.vue"),
      },
      {
        path: "station-config/lite-manager",
        name: "station-config-lite-manager",
        component: () => import("@/views/InternalConfig/LiteManager.vue"),
      },
      {
        path: "station-config/rac-manager",
        name: "station-config-rac-manager",
        component: () => import("@/views/InternalConfig/RacManager.vue"),
      },
      {
        path: "station-config/anydesk",
        name: "station-config-anydesk",
        component: () => import("@/views/InternalConfig/AnyDeskManager.vue"),
      },
      {
        path: "station-config/vnc",
        name: "station-config-vnc",
        component: () => import("@/views/InternalConfig/VncManager.vue"),
      },
      // Internal configs - application, external chat, lockers
      {
        path: "internal-config",
        name: "internal-config",
        component: () => import("@/views/InternalConfig.vue"),
        children: [
          {
            path: "application",
            name: "Application",
            component: () =>
              import("@/views/InternalConfig/ApplicationConfig.vue"),
          },
          {
            path: "reports",
            name: "internal-config-reports",
            component: () => import("@/views/InternalConfig/ReportsConfig.vue"),
          },
          {
            path: "member-accounts",
            name: "internal-config-member-accounts",
            component: () =>
              import("@/views/InternalConfig/MemberAccounts.vue"),
          },
          {
            path: "external-chat",
            name: "ExternalChat",
            component: () => import("@/views/InternalConfig/ExternalChat.vue"),
          },
          {
            path: "lockers",
            name: "Lockers",
            component: () => import("@/views/InternalConfig/LockersConfig.vue"),
          },
        ],
      },
      { path: "*", name: "404", component: () => import("@/views/404.vue") },
    ],
  },
];
