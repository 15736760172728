// ES6 polyfill
import "core-js/stable";
import "mutationobserver-shim";
import Vue from "vue";
import PortalVue from "portal-vue";
// import '../public/static/css/vendors.bundle.css'
// src\assets\metronic\theme\default\dist\assets\vendors\global\vendors.bundle.js
import "./assets/metronic/theme/default/dist/assets/vendors/global/vendors.bundle.css";
// import './assets/metronic/theme/default/src/assets/sass/theme/_config.scss'
import "./assets/metronic/theme/default/src/assets/sass/theme/demos/demo1/style.scss";

// import './assets/metronic/theme/default/src/assets/sass/theme/_config.scss'

import "./assets/metronic/theme/default/src/assets/sass/theme/demos/demo1/header/skins/base/light.scss";

import "./assets/metronic/theme/default/src/assets/sass/theme/demos/demo1/header/skins/menu/light.scss";

import "./assets/metronic/theme/default/src/assets/sass/theme/demos/demo1/brand/skins/dark.scss";

import "./assets/metronic/theme/default/src/assets/sass/theme/demos/demo1/aside/skins/dark.scss";

//import '../public/static/css/datatables.bundle.css'
// import './plugins/bootstrap-vue'
import "./plugins/axios";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import "./registerServiceWorker";
import i18n from "./plugins/i18n";
import defaults from "./defaultStates";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueDraggable from "vue-draggable";
import FullCalendar from "vue-full-calendar";
import HighchartsVue from "highcharts-vue";
import "fullcalendar/dist/fullcalendar.min.css";
import VueApexCharts from "vue-apexcharts";
import FloatingVue from "floating-vue";
import { vTooltip } from "floating-vue";
import Clipboard from "v-clipboard";

Vue.use(FloatingVue);
Vue.use(Clipboard);
Vue.directive("tooltip", vTooltip);
// window.$ = window.jQuery = $

// console.log($)

const getDefaultState = defaults;
store.replaceState({
  ...store.state,
  ...defaults(),
});

Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();

Vue.use(HighchartsVue);

console.log(CKEditor);
Vue.use(CKEditor);
Vue.use(VueDraggable);
Vue.use(PortalVue);
//console.log(process.env)

//import "@fullcalendar/core/main.css";

// console.log(logTypes);

Vue.use(FullCalendar);
Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

i18n.locale = localStorage.getItem("selectedLocale") || "en";

new Vue({
  router,
  store,
  i18n,
  watch: {
    $route(to, from) {
      store.state.isChat = false;
      //let ele = document.getElementsByTagName("body")[0];//document.getElementById('app');
      //ele.className='';
      console.log(to.name, from.name);
      // if(from.name != null)
      //   ele.classList.remove(from.name.toLowerCase()+'-page');
      // ele.classList.add(to.name.toLowerCase()+'-page');
    },
  },
  created() {
    console.log("app created");

    // this.socket = io;
  },
  render: (h) => h(App),
}).$mount("#app");

let stateModule = {
  state() {
    return {
      states: getDefaultState(),
    };
  },
  // mutations: {

  // }
};
store.registerModule("defaultStates", stateModule);
